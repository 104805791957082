import { useEffect, useState } from 'react';
import './style.scss'
import toast from 'react-hot-toast';
import { getBNBPrice, getUserBalance, scBuyWithBNB, scBuyWithBUSD, scBuyWithUSDT, scClaimTokens, scGetICOEngineInfo } from 'utils/contracts';
import { ICOEngineDetail_V2, UserBalance } from 'utils/typs';

import { currentNetwork, truncateWalletString } from 'utils';
import Button1 from 'components/Buttons/button1/Button1';
import MySelect from 'components/Buttons/MySelect';
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import BtnTimer from 'components/timer/BtnTimer';

type PropsType = {
    setShowConnectModal?: (flag: boolean) => void;
    setShowBuyModal?: (flag: boolean) => void;
    setShowAccountModal?: (flag: boolean) => void;
    setShowHelpModal?: (flag: boolean) => void;
};
export default function ICOSection({ setShowConnectModal, setShowAccountModal, setShowBuyModal, setShowHelpModal }: PropsType) {
    const { loginStatus, chainId, library, account } = useActiveWeb3();
    const {openConnectModal} = useConnectModal();
    
    useEffect(() => {
        if (loginStatus) {
            getUserBalance(account).then(
                (balance) => {
                    setUserBalance(balance);
                }
            );
        }

        scGetICOEngineInfo(account).then((engineInfo) => {
            setICOEngineINfo(engineInfo);
            setIsLoadingApi(false);
        });
    }, [account, loginStatus]);


    const [icoEngineInfo, setICOEngineINfo] = useState<ICOEngineDetail_V2>(null);
    const [isLoadingApi, setIsLoadingApi] = useState(true);
    const [bnbPrice, setBNBPrice] = useState(0.00);
    const [userBalance, setUserBalance] = useState<UserBalance>(null);
    
    useEffect(() => {
        getBNBPrice().then((price) => {
            setBNBPrice(price);
        });
    }, [])

    const [coinID, setCoinID] = useState(0)
    const onClickCoinBtn = (id: number) => {
        setCoinID(id)
    }
    const onClickCard = () => {

        toast.success("This feature will be available soon!");
        //setCoinID(1)
        // setShowCardModal(true)
    }

    const [payAmount, setPayAmount] = useState<number>(0.00);
    const [payAmountStr, setPayAmountStr] = useState('');
    const [receiveAmount, setReceiveAmount] = useState<number>(0);
    const [receiveAmountStr, setReceiveAmountStr] = useState('');
    const [maxVal, setMaxVal] = useState<number>(0.00);
    const [isPayFocused, setIsPayFocused] = useState(false);
    


    

    useEffect(() => {
        if (icoEngineInfo?.USDTToTokenRate !== 0) {
            if (coinID === 0 && bnbPrice !== 0) {
                setReceiveAmount(payAmount * bnbPrice * icoEngineInfo?.USDTToTokenRate || 0)
            } else {
                setReceiveAmount(payAmount * icoEngineInfo?.USDTToTokenRate || 0)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coinID]);

    const rankingOption = [
        { label: <span className='coin_label'><img src="/assets/icons/bnb.png" alt="" /> BNB</span>, value: 0 },
        { label: <span className='coin_label'><img src="/assets/icons/icon_busd.webp" alt="" /> BUSD</span>, value: 1 },
        { label: <span className='coin_label'><img src="/assets/icons/icon_usdt.svg" alt="" /> USDT</span>, value: 2 },
    ]

    const onClickConnectBtn = () => {
        if (loginStatus) {
            onBuy();
        } else {
            openConnectModal();
        }
    }
    const onClickDisConnectBtn = () => {
        setShowAccountModal(true);
    }
    const onChangePayAmount = (e: any) => {
        setPayAmount(parseFloat(e.target.value));
        setPayAmountStr(e.target.value)
        if (bnbPrice && icoEngineInfo?.USDTToTokenRate) {
            if (coinID === 0 && bnbPrice !== 0) {
                setReceiveAmount(parseFloat(e.target.value) * bnbPrice * icoEngineInfo?.USDTToTokenRate);
            } else {
                setReceiveAmount(parseFloat(e.target.value) * icoEngineInfo?.USDTToTokenRate);
            }
        }
        setIsPayFocused(false)
    }
    const onChangeReceiveAmount = (e: any) => {
        setReceiveAmount(parseFloat(e.target.value))
        setReceiveAmountStr(e.target.value)
        if (coinID === 0 && bnbPrice !== 0) {
            setPayAmount(parseFloat(e.target.value) / bnbPrice / icoEngineInfo?.USDTToTokenRate);
        } else {
            setPayAmount(parseFloat(e.target.value) / icoEngineInfo?.USDTToTokenRate);
        }

        setIsPayFocused(true)
    }
    const onClickPayAmount = (e: any) => {

        setPayAmount(parseFloat(e.target.value));
        setPayAmountStr(e.target.value)
        setIsPayFocused(false)
    }
    const onClickReceiveAmount = (e: any) => {
        setReceiveAmount(parseFloat(e.target.value))
        setReceiveAmountStr(e.target.value)
        setIsPayFocused(true)
    }

    const onChangeMax = () => {
        // if(!loginStatus) {
        //     toast.error("Please connect wallet correctly!");
        //     return;
        // }
        if (coinID === 0) {
            setMaxVal(userBalance?.BNBBalance || 0.00)
        }
        if (coinID === 1) {
            setMaxVal(userBalance?.BUSDBalance || 0.00)
        }
        if (coinID === 2) {
            setMaxVal(userBalance?.USDTBalance || 0.00)
        }
    }
    useEffect(() => {
        if (icoEngineInfo?.USDTToTokenRate !== 0 && userBalance) {
            if (coinID === 0 && bnbPrice !== 0) {
                setReceiveAmount(maxVal * bnbPrice * icoEngineInfo?.USDTToTokenRate || 0)

            } else {
                setReceiveAmount(maxVal * icoEngineInfo?.USDTToTokenRate || 0)
            }
            setPayAmount(maxVal);
            setPayAmountStr(maxVal.toFixed(3))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coinID, maxVal, userBalance]);

    const onBuyNowHander = async () => {
        if (!loginStatus) {
            await onClickConnectBtn();
        } 
        // else if (localStorage.getItem(`TradeHub_${account.toLowerCase()}`)) {
        //     await onBuy();
        // } else {
        //     setShowBuyModal(true);
        // }
        else {
            await onBuy();
        }
    };

    const onBuy = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        if (coinID === 0) {
            if (payAmount < 0.02) {
                toast.error("Pay amount must be higher than 0.2 BNB");
                return;
            }

            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithBNB(chainId, library, payAmount);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetICOEngineInfo(account).then((engineInfo) => {
                        setICOEngineINfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
        }
        else if (coinID === 1) {
            if (payAmount < 50) {
                toast.error("Pay amount must be higher than 50 USDT");
                return;
            }

            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithUSDT(chainId, library, account, payAmount);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetICOEngineInfo(account).then((engineInfo) => {
                        setICOEngineINfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
        }
        else if (coinID === 2) {
            if (payAmount < 50) {
                toast.error("Pay amount must be higher than 50 BUSD");
                return;
            }

            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithBUSD(chainId, library, account, payAmount);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetICOEngineInfo(account).then((engineInfo) => {
                        setICOEngineINfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
        }
    }

    const onClaim = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        if (icoEngineInfo?.presaleEnded !== true) {
            toast.error("You will be able to claim your tokens once the ICO is completed!");
            return;
        }

        const load_toast_id = toast.loading("Token is claiming now...");
        try {
            const bResult = await scClaimTokens(chainId, library);
            if (bResult) {
                toast.success("Claiming succeed!");
                scGetICOEngineInfo(account).then((engineInfo) => {
                    setICOEngineINfo(engineInfo);
                });
            } else {
                toast.error("Failed!");
            }
        } catch (error) {
            toast.error("Failed!");
        }
        toast.dismiss(load_toast_id);
    }

    return (
        <>
            <div className="ico_section" id='ico_section'>
                <div className="wrapper">
                    <div className="card">
                        {loginStatus && 
                        <div className="card_top">

                            <div className="amount">
                                <h2>Paid Amount : </h2>
                                <h2><span>{icoEngineInfo?.USDTDeposited?.toFixed(3)}</span> USD</h2>
                            </div>
                            <div className="amount">
                                <h2>Estimated Tokens : </h2>
                                <h2><span>{icoEngineInfo?.tokensAmount?.toFixed(3)}</span> TRHUB</h2>
                            </div>
                            {loginStatus && icoEngineInfo?.presaleEnded &&
                                <div className="amount">
                                    <Button1 label={'Claim'} onClick={onClaim} color='color_03' />
                                </div>}
                        </div>}

                        <div className="card_content">
                            <h3><span>Don’t miss the chance!</span></h3>
                            <h2>$TRHUB Pre-Sale</h2>
                            <h3>{`1 $TRHUB = $${Number(1 / icoEngineInfo?.USDTToTokenRate).toFixed(2)}`}</h3>
                            <BtnTimer deadLine={Number(icoEngineInfo?.saleDeadline)}/>
                            <div className="progress-div">
                                <div className="progress" style={{width : `70%`}}></div>
                                {
        icoEngineInfo?.USDTToTokenRate != 1 ?
        <p>Until Next Price ${Number(1 / (icoEngineInfo?.USDTToTokenRate -1)).toFixed(2)} USD</p>
        :
        <p>Until Next Price ${Number(1 / (icoEngineInfo?.USDTToTokenRate)).toFixed(2)} USD</p>
    }

                            </div>
                            <div className="col_div">
                                <div className="row">
                                    <div className="btns">
                                        <MySelect
                                            value={coinID}
                                            options={rankingOption}
                                            onChange={onClickCoinBtn}
                                            className='filter_select'
                                        />
                                        <button className="button outline" onClick={() => { onClickCard() }}>
                                            <img src="/assets/icons/icon_card.svg" alt="" /> Credit Card
                                        </button>
                                    </div>
                                    
                                </div>

                                <div className="row">
                                    <h4>BNB Amount You Pay</h4>
                                    <div className="input_div">
                                        {isPayFocused ?
                                            <input type="number" value={payAmount.toFixed(3)} onChange={onChangePayAmount} inputMode='decimal' lang="en" step="any" onClick={onClickPayAmount} /> :
                                            <input type="text" value={payAmountStr} onChange={onChangePayAmount} onClick={onClickPayAmount} inputMode='decimal' lang="en" step="any" />
                                        }
                                        <div className="img_div">
                                            
                                            {coinID === 0 ? <img src="/assets/icons/bnb.png" alt="" /> 
                                            : coinID === 1 ? <img src="/assets/icons/icon_busd.webp" alt="" /> : <img src="/assets/icons/icon_usdt.svg" alt="" />}
                                        </div>
                                    </div>
                                    
                                </div>

                                
                                <div className="row">
                                    <h4>TRHUB Amount You Receive:</h4>
                                    <div className="input_div">
                                        {!isPayFocused ?
                                            <input type="number"
                                                value={receiveAmount.toFixed(3)}
                                                onChange={onChangeReceiveAmount} inputMode='decimal' lang="en" onClick={onClickReceiveAmount} /> :
                                            <input type="text"
                                                value={receiveAmountStr}
                                                onChange={onChangeReceiveAmount} inputMode='decimal' lang="en" onClick={onClickReceiveAmount} />
                                        }
                                        <div className="img_div">
                                            <img src="/assets/logo.png" alt="" />
                                        </div>
                                    </div>
                                    

                                </div>
                                <div className="row">
                                    <button className="button full_w" onClick={() => { onBuyNowHander() }}>
                                        {loginStatus ? 'Buy Now' : 'Connect Wallet'} 
                                    </button>
                                </div>
                                {loginStatus &&
                                    <div className="row">
                                        <button className="button full_w" onClick={onClickDisConnectBtn}>
                                            {truncateWalletString(account)} <i className="fas fa-sign-out-alt"></i>
                                        </button>
                                    
                                </div>}
                            </div>
                            <div className="launchBtn" onClick={() => setShowHelpModal(true)}><i className="fas fa-question-circle"></i> Need Help?</div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}
