import "./loading.scss";
type PropsType = {
  isLoading?: boolean;
};

export default function Loading({ isLoading }: PropsType) {
  return (
    <div
      className={`${isLoading ? "loading_page activeLoading" : "loading_page"}`}
    >
      {/* <img className="logoIcon" alt="" src="assets/logo.png" /> */}
      <div className="loading_div">
        <div className="icon">
        <svg width="163.99809000000002" height="187.99928" viewBox="0 0 164 188" fill="none" xmlns="http://www.w3.org/2000/svg" opacity="1"><g filter="url(#filter0_d_2492_23013)"><path d="M154.717 136.831C131.079 176.993 79.3593 190.387 39.1981 166.75M9.27994 51.231C32.9178 11.0698 84.6373 -2.325 124.798 21.3129" stroke="currentColor"></path></g><defs><filter id="filter0_d_2492_23013" x="0.0587301" y="0.348342" width="163.879" height="187.366" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="4.39516"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0.000000
                   0 0 0 0 1.000000
                   0 0 0 0 0.784314
                   0 0 0 1 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2492_23013"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2492_23013" result="shape"></feBlend></filter></defs></svg>
        </div>
        <div className="circle1"></div>
        <div className="circle2"></div>
        <h2>LOADING</h2>
      </div>
      
      
    </div>
  );
}
