import './style.scss'

type PropsType = {
  onClick?: () => void;
  label ?:string
  color ?:string
  loginStatus?:boolean
};

export default function Button1({onClick, label, color, loginStatus}:PropsType) {
  return (
    <button className={`my_btn button ${color}`} onClick={onClick}>
      <div className="button_content">
        <div className="line_1">
          <svg viewBox="0 0 91 80" focusable="false" style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M1.00798 0.978027L1.00803 60.3514C0.84803 65.8101 3.01708 79.978 13 79.978" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
        <div className="line_2">
          <svg viewBox="0 0 91 80" focusable="false" style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M5 0.978027V42.978C5 47.978 5.2 56.978 0 56.978" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
        <div className="line_3">
          <svg viewBox="0 0 91 80" focusable="false"  style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M86.686 0.978027V34.478M86.686 34.478C89.0193 34.1447 90 32.278 90 27.478C90 22.678 90 8.47803 90 1.97803M86.686 34.478V38.478V42.478M1.13623 79.978C1.13623 76.778 6.13623 75.3114 8.63623 74.978H59.1362M59.1362 74.978H77.6362C82.0769 74.978 86.686 72.478 86.686 67.978V42.478M59.1362 74.978C59.4696 73.8114 60.1 69.978 64.5 69.978C68.9 69.978 73.9696 69.978 77.6362 69.978C78.8029 69.978 82.1362 69.978 82.1362 66.478C82.1362 63.678 82.1362 53.978 82.1362 47.978C82.1694 46.6447 84.7258 42.478 86.686 42.478" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
      </div>
      <div className="label">
        {label}
        {color === 'color_01' &&
        <div className="icon">
          {loginStatus ? <svg viewBox="0 0 25 24" focusable="false" strokeWidth="0.5px"><path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M22.5 6H2.5V12H3.5V22H21.5V12H22.5V6Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.02746 5.5H2.5C2.22386 5.5 2 5.72386 2 6V12C2 12.2761 2.22386 12.5 2.5 12.5H3V22C3 22.2761 3.22386 22.5 3.5 22.5H21.5C21.7761 22.5 22 22.2761 22 22V12.5H22.5C22.7761 12.5 23 12.2761 23 12V6C23 5.72386 22.7761 5.5 22.5 5.5H16.9725C16.7238 3.25003 14.8163 1.5 12.5 1.5C10.1837 1.5 8.27619 3.25003 8.02746 5.5ZM9.03544 5.5H15.9646C15.7219 3.80385 14.2632 2.5 12.5 2.5C10.7368 2.5 9.27806 3.80385 9.03544 5.5ZM21.5 11.5H22V6.5H3V11.5H3.5H8V10.5C8 10.2239 8.22386 10 8.5 10C8.77614 10 9 10.2239 9 10.5V11.5H16V10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5V11.5H21.5ZM17 12.5H21V21.5H4V12.5H8V13.5C8 13.7761 8.22386 14 8.5 14C8.77614 14 9 13.7761 9 13.5V12.5H16V13.5C16 13.7761 16.2239 14 16.5 14C16.7761 14 17 13.7761 17 13.5V12.5Z" fill="currentColor"></path></svg>
          :<svg viewBox="0 0 25 24" focusable="false" ><path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M20.9 4.2143H2.5V17.4714C2.5 18.0315 2.5 18.3115 2.60899 18.5254C2.70487 18.7136 2.85785 18.8665 3.04601 18.9624C3.25992 19.0714 3.53995 19.0714 4.1 19.0714H20.9C21.4601 19.0714 21.7401 19.0714 21.954 18.9624C22.1422 18.8665 22.2951 18.7136 22.391 18.5254C22.5 18.3115 22.5 18.0315 22.5 17.4714V14H20C19.5353 14 19.303 14 19.1098 13.9616C18.3164 13.8038 17.6962 13.1836 17.5384 12.3902C17.5 12.197 17.5 11.9647 17.5 11.5C17.5 11.0353 17.5 10.803 17.5384 10.6098C17.6962 9.81644 18.3164 9.19625 19.1098 9.03843C19.303 9 19.5353 9 20 9H22.5V5.8143C22.5 5.25425 22.5 4.97422 22.391 4.76031C22.2951 4.57215 22.1422 4.41917 21.954 4.32329C21.7401 4.2143 21.4601 4.2143 20.9 4.2143Z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M2.5 3.71434C2.22386 3.71434 2 3.9382 2 4.21434V19.0714C2 19.3476 2.22386 19.5714 2.5 19.5714H22.5C22.7761 19.5714 23 19.3476 23 19.0714V14.4865L24.4955 14.5C24.6289 14.5012 24.7572 14.449 24.852 14.3551C24.9467 14.2612 25 14.1334 25 14V8.99999C25 8.72385 24.7761 8.49999 24.5 8.49999H23V4.21434C23 3.9382 22.7761 3.71434 22.5 3.71434H12.5H2.5ZM22.499 9.49999C22.4993 9.49999 22.4997 9.49999 22.5 9.49999C22.5003 9.49999 22.5007 9.49999 22.501 9.49999H24V13.4954L19.9616 13.4589C18.8754 13.4491 18 12.5658 18 11.4795C18 10.3862 18.8863 9.49999 19.9795 9.49999H22.499ZM22 8.49999V4.71434H12.5H3V18.5714H22V14.4774L19.9526 14.4589C18.3176 14.4441 17 13.1145 17 11.4795C17 9.83397 18.334 8.49999 19.9795 8.49999H22Z" fill="currentColor"></path></svg>
          }
        </div>}
        {color === 'color_02' && <i className="fas fa-sign-out-alt"></i>}
        {color === 'color_03' && <div className="icon"><svg viewBox="0 0 19 19" focusable="false" ><g><path fillRule="evenodd" clipRule="evenodd" d="M4.65942 16.3906H3.15942C2.74542 16.3906 2.40942 16.0546 2.40942 15.6406V11.1406C2.40942 10.7266 2.74542 10.3906 3.15942 10.3906H4.65942C5.07342 10.3906 5.40942 10.7266 5.40942 11.1406V15.6406C5.40942 16.0546 5.07342 16.3906 4.65942 16.3906Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.90942 13.3899H11.6592C11.9839 13.3899 12.2997 13.2849 12.5592 13.0899L14.3712 11.7309C14.8234 11.3919 15.4564 11.4369 15.8562 11.8367V11.8367C16.3002 12.2807 16.3002 12.9999 15.8562 13.4432L14.3014 14.9979C13.8829 15.4164 13.3489 15.7022 12.7684 15.8184L10.5709 16.2579C10.1352 16.3449 9.68592 16.3344 9.25467 16.2264L7.26717 15.7299C7.02942 15.6699 6.78567 15.6399 6.54042 15.6399H5.40942" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.90942 13.3906H11.0232C11.6509 13.3906 12.1594 12.8821 12.1594 12.2544V12.0271C12.1594 11.5059 11.8047 11.0514 11.2992 10.9254L9.58017 10.4956C9.30042 10.4259 9.01392 10.3906 8.72592 10.3906V10.3906C8.03067 10.3906 7.35042 10.5961 6.77217 10.9824L5.40942 11.8906" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.0359 4.50624C13.8672 5.33747 13.8672 6.68516 13.0359 7.51639C12.2047 8.34762 10.857 8.34762 10.0258 7.51639C9.19454 6.68516 9.19454 5.33747 10.0258 4.50624C10.857 3.675 12.2047 3.675 13.0359 4.50624" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11.1327 3.92188C11.0629 3.49812 10.8694 3.09087 10.5424 2.76388C9.71142 1.93288 8.36367 1.93288 7.53267 2.76388C6.70167 3.59487 6.70167 4.94262 7.53267 5.77362C8.05017 6.29112 8.76717 6.48537 9.43542 6.35862" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path></g></svg></div>}
      </div>
    </button>
  )
}
