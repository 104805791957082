import { useEffect, useRef, useState } from 'react';
import './token.scss'
import toast from 'react-hot-toast';

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;

};
export default function Token({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 1){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    const inputEl = useRef<HTMLInputElement>(null);
    const clickHandle = () => {
      if (inputEl && inputEl.current) {
        navigator.clipboard.writeText('');
        toast.success('Copied Address!');
      }
    };
    
    return (
        <div className="token" id="token">
            <div className="tokenContent">
                <div className="title" data-aos="fade-up">
                    <h1>Token Contract</h1>
                </div>

                <div className="wrapper">
                    <div className="col border_right" data-aos="fade-right">
                        <div className="col_content">
                        <h3>Address:</h3>
                        
                        <span>
                            <p>Coming Soon</p>
                            <button className="coppyAddressButton" onClick={clickHandle}>
                                <i className="fas fa-copy"></i>
                            </button>
                            <img src="/assets/metamask.png" alt="" />
                        </span>
                        </div>
                    </div>
                    <div className="col border_right" data-aos="fade-up">
                        <div className="col_content">
                        <h3>Demical:</h3>
                        <p>18</p>
                        </div>
                    </div>
                    <div className="col border_right" data-aos="fade-left">
                        <div className="col_content">
                        <h3>Network:</h3>
                        <p>BNB-Chain</p>
                        </div>
                    </div>
                    <div className="col" data-aos="fade-left">
                        <div className="col_content">
                        <h3>Token Symbol:</h3>
                        <p>$TRHUB</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="/assets/images/Frame_08.png" alt="" className="effect" onLoad = {onLoad}/>
        </div>
    )
}
