import { useEffect, useState } from "react";
import "./faq.scss"
import Expand from "react-expand-animated";
const faqData = [
    {
        id:0,
        quetion : "What is TradeHub?",
        answer : "TradeHub is a cutting-edge platform that integrates artificial intelligence with trading, offering automated trading solutions, AI-powered market predictions, and strategy optimization to enhance your trading performance."
    },
    
    {
        id:1,
        quetion : "How does TradeHub ensure the security of my investments?",
        answer : "TradeHub prioritizes the security of your investments by employing advanced encryption, continuous monitoring for suspicious activity, and compliance with industry-standard financial regulations."
    },
    {
        id:2,
        quetion : "Can I test TradeHub's features before committing to a subscription?",
        answer : "Absolutely, TradeHub offers a 30-day free trial period during which you can explore all the features of our platform. Additionally, we provide a complimentary 30-minute onboarding call to help you get the most out of your trial experience."
    },
    
]

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Faq({setIsLoading}:PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 1){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    

    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];

    const [faqId, setFaqId] = useState(-1);
    const handleClick = (id:number)=>{
        if (faqId !== -1){
            if(faqId === id){
                setFaqId(-1)
            }
            else{
                setFaqId(id)
            }
        }
        else{
            setFaqId(id)
        }

    }
    return (
        <div className="faq">
            <div className="faqContent">
                <div className="scroll" id="faq"></div>
                <div className="title" data-aos="fade-up">
                    <h4>FAQS</h4>
                    <h1>Frequently Asked questions</h1>
                    
                </div>
                <div className="wrapper">
                    <div className="left" data-aos="fade-right">
                        {faqData.map((d)=>(
                            <div className="line" key = {d.id} onClick={()=>{handleClick(d.id)}}>
                                <div className="question">
                                    <p>{d.quetion}</p>

                                    {d.id === faqId?
                                        <i className="show fas fa-minus-circle"></i>:
                                        <i className="show fas fa-plus-circle"></i>
                                    }

                                    {/* <i className="show fas fa-chevron-right" style = {{transform: d.id === faqId? "rotate(90deg)" : "rotate(0deg)"}}></i> */}
                                </div>
                                <Expand
                                    open={faqId === d.id}
                                    duration={300}
                                    styles={styles}
                                    transitions={transitions}
                                >
                                    <p className="answer">{d.answer}</p>
                                </Expand>
                                
                            </div>
                        ))}
                    </div>
                    <div className="right">
                        <img src="/assets/images/faq_img.png" alt="" onLoad = {onLoad}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
