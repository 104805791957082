import './tokenModal.scss'
import Bounce  from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
import CardBackground from 'components/Layout/CardBackground';
import Button2 from 'components/Buttons/button2/Button2';
import { apiAddWalletAddress } from 'utils/contracts';
import { useAccount, useDisconnect } from 'wagmi';
interface Props {
    showModal: boolean,
    setShowModal?: any

}
const TokenModal: React.FC<Props> = ({
    showModal,
    setShowModal
}) => {
    const {disconnect} = useDisconnect();
    const { address } = useAccount();
    const onAccept = () => {
        apiAddWalletAddress(address)
        setShowModal(false);
    }
    const onDisconnect = () => {
        disconnect();
        setShowModal(false);
    }



    const [isStart, setIsStart] = useState(false)
    useEffect(() => {
        if(showModal)
        {
            
            setTimeout(() => {
                setIsStart(true)
            }, 100)
        }
        
    }, [setIsStart,  showModal]);
    const onClose = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowModal(false);
        }, 800)
    }
    return (
        <div className={showModal === true ? "tokenModal active" : "tokenModal"}>
            <Bounce opposite when={isStart}>
            <div className="modelContent">
                <div className="connectWalletHeader">
                    <button className="connectModalCloseButton" onClick={onClose}><i className="fas fa-times"></i></button>
                </div>
                <div className="connectWalletWrapper">
                    <p>I have read and agree to the SAFT terms and conditions which must be accepted before I can participate in any Token Purchase. The Terms contain limitations on liabilities and impact my rights as a purchaser.</p>
                    <Button2 label='Accept' icon={<i className="fas fa-check-circle"></i>} onClick={onAccept} color='color_02' type='top_left'/>
                    
                    <Button2 label='Decline' icon={<i className="fas fa-times-circle"></i>} onClick={onDisconnect} color='color_01' type='bottom_right'/>

                    
                </div>
                <CardBackground color='color_02'/>
            </div>
            </Bounce>

        </div>
    )
}
export default TokenModal;