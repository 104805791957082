import { useEffect, useState } from 'react';
import './press_release.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Blog({setIsLoading}:PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 5){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="press_release" id="why-participate">
            <div className="content">
                <div className="title" data-aos="fade-up">
                    <h1>Blog</h1>
                    <p>Latest articles and updates about TradeHub</p>
                </div>
                <div className="wrapper">
                    <ul>
                        <li data-aos="fade-up">
                            <img src="/assets/images/PressRelease.png" alt="" onLoad = {onLoad}/>
                            
                            <h2>Automated Trading</h2>
                            <p>Tradehub automates trading processes by executing trades based on predefined rules and algorithms.</p>
                        </li>
                        <li data-aos="fade-up">
                            <img src="/assets/images/PressRelease.png" alt="" onLoad = {onLoad}/>
                            <h2>AI-Powered Signals</h2>
                            <p>Tradehub AI-powered algorithms can provide traders with accurate market analysis and predictions, helping them make more informed trading decisions.</p>
                        </li>
                        <li data-aos="fade-up">
                            <img src="/assets/images/PressRelease.png" alt="" onLoad = {onLoad}/>
                            <h2>Trade Algorithm</h2>
                            <p>Tradehub AI can develop and optimize trading strategies based on historical data and market conditions.</p>
                        </li>
                        <li data-aos="fade-up">
                            <img src="/assets/images/PressRelease.png" alt="" onLoad = {onLoad}/>
                            <h2>Fraud Detection</h2>
                            <p>Tradehub AI algorithms can help detect fraudulent trading activities by analyzing large volumes of trading data and identifying suspicious patterns or anomalies.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <img src="/assets/images/Frame_07.png" alt="" className="effect" onLoad = {onLoad}/>
        </div>
    )
}
