import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link'
import './footer.scss'


type LoadingType = {
    setIsLoading?(flag: boolean): void;
};

export default function Footer({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 6) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="footer">
            <div className="footerContent" data-aos="fade-up">
                <div className="wrapper">
                    <div className="left">
                        <img src={'/assets/trade_hub_logo.png'} alt="" className="logo" onLoad = {onLoad}/>
                        <p>Tradehub is an AI-based system designed to help traders make more informed decisions by providing them with real-time insights and predictions of future price movements.</p>
                    </div>
                    <div className="right">
                        <div className="col">
                            <h3>Product</h3>
                            <a href="/assets/Overview.pdf" target="_blank"rel="noreferrer">Overview</a>
                            <a href="/assets/Features.pdf" target="_blank"rel="noreferrer">Features</a>
                            {/* <HashLink to={'#home'} smooth >Solutions</HashLink>
                            <HashLink to={'#home'} smooth >Tutorials</HashLink>
                            <HashLink to={'#home'} smooth >Pricing</HashLink>
                            <HashLink to={'#home'} smooth >Releases</HashLink> */}
                        </div>
                        <div className="col">
                            <h3>Company</h3>
                            <HashLink to={'#home'} smooth >About us</HashLink>
                            {/* <HashLink to={'#home'} smooth >Careers</HashLink>
                            <HashLink to={'#home'} smooth >Press</HashLink> */}
                            <HashLink to={'#home'} smooth >News</HashLink>
                            <a href="/assets/Terms.pdf" target="_blank"rel="noreferrer">Terms of Use</a>
                            <HashLink to={'#home'} smooth >Contact</HashLink>
                            
                        </div>
                        <div className="col">
                            <h3>Resources</h3>
                            <HashLink to={'#home'} smooth >Blog</HashLink>
                            {/* <HashLink to={'#home'} smooth >Newsletter</HashLink> */}
                            {/* <a href="/assets/Legal.pdf" target="_blank"rel="noreferrer">Legal</a> */}
                            {/* <HashLink to={'#home'} smooth >Help Center</HashLink>
                            <HashLink to={'#home'} smooth >Tutorials</HashLink> */}
                            <a href="mailto:support@tradehub.ai" target="_blank"rel="noreferrer">Support</a>
                            
                        </div>
                    </div>
                </div>
                <div className="foot">
                    <span>© 2023 TradeHub. All rights reserved.</span>
                        <div className="socialLinks">
                            <a href="Https://twitter.Com/@tradehubai" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_twitter.svg" alt="" />
                            </a> 
                            <a href="https://t.me/tradehubai_Group" target="_blank"rel="noreferrer">
                                <i className="fab fa-telegram"></i>
                            </a>
                            <a href="https://discord.gg/7VBTafZe" target="_blank"rel="noreferrer">
                                <i className="fab fa-discord"></i>
                            </a>
                            <a href="https://youtube.com/@TradehubAi" target="_blank"rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                            <a href="https://www.instagram.com/tradehubai" target="_blank"rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a> 
                            {/* <a href="Https://linkedin.Com/" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_linkedin.svg" alt="" />
                            </a> 
                            
                            <a href="https://facebook.com" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_facebook.svg" alt="" />
                            </a>  */}
                            {/* <a href="https://github.com/" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_github.svg" alt="" />
                            </a> 
                            <a href="/" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_finger.svg" alt="" />
                            </a> 
                            <a href="/" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_global.svg" alt="" />
                            </a>  */}
                        </div>

                </div>
                
            </div>
        </div>
    )
}
 