import { useEffect, useState } from 'react';
import './contact.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function Contact({setIsLoading}:PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 2){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="contact" id='contact'>
            <div className="content">
                
                <div className="wrapper">
                    <div className="left">
                        <img src="/assets/images/69 1.png" alt="" onLoad = {onLoad}/>
                    </div>
                    <div className="right">
                        <div className="title" data-aos="fade-up">
                            <h1>Contact Form</h1>
                        </div>
                        <div className="myform">
                            <div className="row">
                                <h3>Full Name</h3>
                                <input type="text" placeholder='Enter Your Name' />
                            </div>
                            <div className="row">
                                <h3>Email</h3>
                                <input type="text" placeholder='Enter Your Email' />
                            </div>
                            <div className="row">
                                <h3>Message Text</h3>
                                <textarea placeholder='Enter Your Message' />
                            </div>

                            <div className="row">
                                <button className="button">Send</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="effect2"></div>
            <img src="/assets/images/Frame_06.png" alt="" className="effect1" onLoad = {onLoad}/>
        </div>
    )
}
