
import { useEffect, useState } from 'react';
import { truncateWalletString } from 'utils';
import { getUserBalance } from 'utils/contracts';
import AccountModal from "components/Modals/accountModal/AccountModal";
import './topbar.scss'
import { UserBalance } from 'utils/typs';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom';

type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [userBalance, setUserBalance] = useState<UserBalance>(null);

    const {openConnectModal} = useConnectModal();

    const { loginStatus, account } = useActiveWeb3();
    
    useEffect(() => {
        if (loginStatus) {
            getUserBalance(account).then(
                (balance) => {
                    setUserBalance(balance);
                }
            );
        }
    }, [account, loginStatus]);

    const [navId, setNavId] = useState('')
    const search = useLocation();
    
    useEffect(() => {
        const label = search.hash.replace('#', '')
        setNavId(label)
    }, [setNavId, search]);


    return (
        <div className="topbar">
            <div className="topbar_content">
            <div className="logo">
                    <HashLink to="/#" smooth>
                        <img src="/assets/trade_hub_logo.png" alt="" />
                    </HashLink>
                </div>
                <ul className="nav_list">
                    <li>
                        <HashLink to="#" smooth className = {navId === '' ? 'active':''}>
                            Home
                        </HashLink>
                    </li>
                    <li>
                    <li>
                        <a href="/assets/Tradehub.pdf" target="_blank" rel="noreferrer">WhitePaper</a>
                    </li>
                    </li>
                    <li>
                    <li>
                        <a href="/assets/users_history.pdf" target="_blank" rel="noreferrer">Presale History</a>
                    </li>
                    </li>
                    <li>
                        <a href="/assets/Terms.pdf" target="_blank" rel="noreferrer">Terms Of Use</a>
                    </li>
                    <li>
                        <HashLink to="#contact" smooth>
                            Contact
                        </HashLink>
                    </li>
                </ul>
            <div className="btns">
                

                {loginStatus && <div className="col">
                    <div className="balance button">
                        <img src="/assets/icons/bnb.png" alt="" />
                        <p><span> {userBalance?.BNBBalance?.toFixed(3) || "0.000"} </span> BNB</p>
                    </div>
                    <div className="balance button">
                        <img src="/assets/icons/icon_usdt.svg" alt="" />
                        <p><span> {userBalance?.USDTBalance?.toFixed(3) || "0.000"} </span> USDT</p>
                    </div>
                    <div className="balance button">
                        <img src="/assets/icons/icon_busd.webp" alt="" />
                        <p><span> {userBalance?.BUSDBalance?.toFixed(3) || "0.000"} </span> BUSD</p>
                    </div>
                </div>}

                <div className="connectBtn button" onClick={() => { !loginStatus ? openConnectModal() : setShowAccountModal(true); }}>
                    <p><img src="/assets/icons/icon_wallet.svg" alt="" /> {loginStatus ? truncateWalletString(account) : "Connect Wallet"}</p>
                </div>
            </div>
            </div>


            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            <AccountModal showAccountModal={showAccountModal} setShowAccountModal={setShowAccountModal} />
        </div>
    )
}
