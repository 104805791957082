import { useEffect, useState } from 'react';
import './home.scss'
import ICOSection from 'components/ICOSection/ICOSection';
import AccountModal from 'components/Modals/accountModal/AccountModal';
import Modal from 'components/Modals/BuyModal/Modal';
import TokenModal from 'components/Modals/tokenModal/TokenModal';
import HelpModal from 'components/Modals/helpModal/HelpModal';
import CardModal from 'components/Modals/CardModal/CardModal';
type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};

export default function Home({setIsLoading}:PropsType) {

    const [showConnectModal, setShowConnectModal] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showCardModal, setShowCardModal] = useState(false);

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 1){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    return (
        <>
        <div className="home" id = "home">
            <div className="homeContent">
                <div className="homeWrapper">
                    <div className="homeLeft">
                        <h1><span>AI Predictions</span> for Optimal Trading </h1>
                        <p>Where Artificial Intelligence Meets Trading Excellence.</p>
                        <div className="socialLinks">
                            {/* <a href="https://facebook.com" target="_blank"rel="noreferrer">
                                <i className="fab fa-facebook"></i>
                            </a>  */}
                            <a href="Https://twitter.Com/@tradehubai" target="_blank"rel="noreferrer">
                                <img src="/assets/icons/icon_twitter.svg" alt="" />
                            </a> 
                            <a href="https://t.me/tradehubai" target="_blank"rel="noreferrer">
                                <i className="fab fa-telegram"></i>
                            </a> 
                            <a href="https://discord.gg/7VBTafZe" target="_blank"rel="noreferrer">
                                <i className="fab fa-discord"></i>
                            </a> 
                            <a href="https://youtube.com/@TradehubAi" target="_blank"rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a> 
                            
                            <a href="https://www.instagram.com/tradehubai" target="_blank"rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a> 
                        </div>
                    </div>
                    <div className="homeRight">
                    <ICOSection 
                        // setShowCardModal = {setShowCardModal} 
                        setShowConnectModal = {setShowConnectModal} 
                        setShowAccountModal = {setShowAccountModal} 
                        setShowBuyModal = {setShowTokenModal}
                    />
                    </div>
                    
                    
                </div>
            </div>
            <img src="/assets/images/Frame.png" alt="" className="effect1" onLoad={onLoad}/>
        </div>

        
        <AccountModal showAccountModal={showAccountModal} setShowAccountModal={setShowAccountModal} />
        <Modal 
            showModal={showBuyModal} 
            setShowModal = {setShowBuyModal} 
            children={
                <>
                <div id="wert-widget"></div>
                </>
            } 
        />
        
        <TokenModal showModal={showTokenModal} setShowModal={setShowTokenModal}/>
        
        <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal}/>
        <CardModal showModal={showCardModal} setShowModal={setShowCardModal}/>
        </>
        
    )
}
