import "./menu.scss"
import { HashLink } from 'react-router-hash-link'
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
type MenuType = {
    menuOpen: boolean;
    setMenuOpen(flag: boolean): void;
};

export default function Menu({ menuOpen, setMenuOpen }: MenuType) {
    const [navId, setNavId] = useState('')
    const search = useLocation();
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 640px) and (orientation:portrait)",});
    
    useEffect(() => {
        const label = search.hash.replace('#', '')
        setNavId(label)
    }, [setNavId, search]);
    return (
        <div className={"sidebar " + (menuOpen && "active")}>
            <div className="content">
                <ul>
                    <li onClick={()=>isTabletOrMobile && setMenuOpen(false)} className={`menuItem1 ${menuOpen ? "active":""} ${navId === ''? 'selected':''}`}>
                        <HashLink to="/#" smooth><i className="fas fa-caret-right"></i> Home</HashLink>
                    </li>
                    <li onClick={()=>isTabletOrMobile && setMenuOpen(false)} className={`menuItem1 ${menuOpen ? "active":""} ${navId === 'about'? 'selected':''}`}>
                        <a href="/assets/Tradehub.pdf" target="_blank" rel="noreferrer"><i className="fas fa-caret-right"></i> WhitePaper</a>
                    </li>
                    
                    <li onClick={()=>isTabletOrMobile && setMenuOpen(false)} className={`menuItem2 ${menuOpen ? "active":""} ${navId === 'howtobuy'? 'selected':''}`}>
                     <a href="/assets/users_history.pdf" target="_blank" rel="noreferrer"><i className="fas fa-caret-right"></i> Presale History</a>
                    </li>
                    <li onClick={()=>isTabletOrMobile && setMenuOpen(false)} className={`menuItem3 ${menuOpen ? "active":""} ${navId === 'terms'? 'selected':''}`}>
                        <a href="/assets/Terms.pdf" target="_blank" rel="noreferrer"><i className="fas fa-caret-right"></i> Terms Of Use</a>
                    </li>
                    
                    <li onClick={()=>isTabletOrMobile && setMenuOpen(false)} className={`menuItem7 ${menuOpen ? "active":""} ${navId === 'contact'? 'selected':''}`}>
                        <HashLink to="/#contact" smooth><i className="fas fa-caret-right"></i> Contact</HashLink>
                    </li>
                    
                </ul>
                
                <div className="close_btn" onClick={()=>setMenuOpen(!menuOpen)} style={{transform : `rotate(${menuOpen ? 90:-90}deg)`}}>
                    {menuOpen ? 
                    <svg viewBox="0 0 18 18" focusable="false" className="chakra-icon css-1ewd8qv"><path d="M2.00001 6.38473C1.9995 6.31015 2.02083 6.23706 2.06135 6.17446C2.10188 6.11186 2.15984 6.06248 2.22809 6.03242C2.29634 6.00236 2.37189 5.99293 2.44543 6.00528C2.51898 6.01764 2.5873 6.05124 2.64198 6.10195L9.00054 11.8759L14.4191 6.95409C14.4563 6.91997 14.5 6.89358 14.5475 6.87644C14.595 6.8593 14.6455 6.85175 14.696 6.85424C14.7464 6.85673 14.7959 6.8692 14.8415 6.89093C14.8871 6.91266 14.928 6.94322 14.9617 6.98084C15.0294 7.05592 15.0646 7.15478 15.0596 7.25577C15.0546 7.35676 15.0098 7.45164 14.9349 7.51964L9.25656 12.6745C9.18635 12.7379 9.09512 12.773 9.00054 12.773C8.90595 12.773 8.81473 12.7379 8.74452 12.6745L2.12229 6.6675C2.0834 6.63143 2.05244 6.58767 2.03139 6.53898C2.01034 6.4903 1.99965 6.43777 2.00001 6.38473ZM15.2368 6.38473C15.2365 6.33444 15.2462 6.28458 15.2652 6.23803C15.2842 6.19147 15.3122 6.14912 15.3476 6.11342C15.3825 6.07768 15.4241 6.04911 15.4699 6.02935C15.5161 6.01108 15.5654 6.00199 15.6151 6.0026C15.6661 6.00149 15.7168 6.01058 15.7641 6.02935C15.8092 6.05061 15.8505 6.079 15.8864 6.11342C15.9242 6.14804 15.9531 6.19131 15.9705 6.23952C15.9992 6.30833 16.0072 6.38405 15.9934 6.45733L15.9705 6.52993L15.9361 6.5949C15.9226 6.61647 15.9059 6.63581 15.8864 6.65221C15.8525 6.68909 15.8107 6.71783 15.7641 6.73628C15.717 6.75636 15.6663 6.76675 15.6151 6.76685C15.5652 6.76625 15.5159 6.75586 15.4699 6.73628C15.3781 6.69522 15.3046 6.62176 15.2636 6.52993C15.2453 6.48373 15.2362 6.43441 15.2368 6.38473Z" fill="currentColor"></path></svg>:
                    'MENU'
                    }
                </div>
            </div>
        </div>
    )
}

