import { Contract } from '@ethersproject/contracts'
import PresaleContract_ABI from 'contracts/PresaleContract.json'
import IERC20Metadata_ABI from 'contracts/IERC20Metadata.json'

export const Networks = {
  BSCMainNet: 56
}

export const CONTRACTS_BY_NETWORK = {
  [Networks.BSCMainNet]: {
    PresaleContract: {
      address: '0x2C9E88E8e9a2b322092d717A79C53034b1b20261',
      abi: PresaleContract_ABI,
    },
    USDT: {
      address: '0x55d398326f99059fF775485246999027B3197955',
      abi: IERC20Metadata_ABI,
    },
    BUSD: {
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      abi: IERC20Metadata_ABI,
    },
    TokenForSale: {
      address: '0x94beB7bAC1708EDC56B4c7bcbA4c3e31e151E06C',
      abi: IERC20Metadata_ABI,
    }
  }
}

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;
export const walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const baseApiUrl = process.env.REACT_APP_API_URL;


export function getContractInfo(name, chainId = null) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + '...' + endStr;
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + '...' + endStr;
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getContractObjWithAddress(name, chainId, provider, contractAddress) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(contractAddress, info.abi, provider);
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str
