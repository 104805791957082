import "@ethersproject/shims"
import { currentNetwork, getContractObj } from ".";
import { ICOEngineDetail_V2, UserBalance } from "./typs";
import { BigNumber, ethers } from "ethers";
import { bsc } from 'wagmi/chains'
import toast from "react-hot-toast";
import Airtable from "airtable";

var base = new Airtable({ apiKey: 'keyHy7oSJdm2Ap399' }).base('apphUD3xwayXIBETM');

export async function getUserBalance(account) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(bsc.rpcUrls.public.http[0]);
    const USDTContract = getContractObj('USDT', currentNetwork, jsonProvider);
    const BUSDContract = getContractObj('BUSD', currentNetwork, jsonProvider);
    try {
        const [
            BNBBalance,
            USDTBalance,
            BUSDBalance,
        ] = await Promise.all([
            account ? jsonProvider.getBalance(account) : BigNumber.from(0),
            account ? USDTContract.balanceOf(account) : BigNumber.from(0),
            account ? BUSDContract.balanceOf(account) : BigNumber.from(0),
        ]);

        const userBalance: UserBalance = {
            BNBBalance: parseFloat(ethers.utils.formatEther(BNBBalance)),
            USDTBalance: parseFloat(ethers.utils.formatEther(USDTBalance)),
            BUSDBalance: parseFloat(ethers.utils.formatEther(BUSDBalance)),
        }

        return userBalance;
    } catch (e) {
        console.log(e);
        return null;
    }
}

/********************************************* ICO For V2 *********************************************/

export async function getBNBPrice() {
    const data = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD");
    const jsonData = await data.json();
    return jsonData.USD;
}

export function apiAddWalletAddress(address: string) {
    base('TradeHub ICO Wallets').create([
        {
            "fields": {
                "Address": address
            }
        }
    ], function (err, records) {
        if (err) {
            console.error(err);
            return;
        }
        records.forEach(function (record) {
            if (!localStorage.getItem(`TradeHub_${address.toLowerCase()}`)) {
                localStorage.setItem(`TradeHub_${address.toLowerCase()}`, record.getId());
            }
        });
    });
}


export async function scGetICOEngineInfo(account) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(bsc.rpcUrls.public.http[0]);
    const ICOContract = getContractObj('PresaleContract', currentNetwork, jsonProvider);
    const tokenContract = getContractObj('TokenForSale', currentNetwork, jsonProvider);
    

    try {
        const [
            tokenDecimals,
            currentRoundData,
            presaleEnded,
            saleDeadline,
            userData
        ] = await Promise.all([
            tokenContract.decimals(),
            ICOContract.get_current_round(),
            ICOContract.presale_ended(),
            ICOContract.presaleRoundEndTime(),
            account ? ICOContract.users_list(account) : null,
        ]);

        const ICOEngine: ICOEngineDetail_V2 = {
            USDTToTokenRate: currentRoundData[1].toNumber(),
            USDTRoundRaised: parseFloat(ethers.utils.formatUnits(currentRoundData[2], 18)),
            USDTRoundCap: parseFloat(ethers.utils.formatUnits(currentRoundData[3], 18)),
            presaleEnded: presaleEnded,
            saleDeadline: saleDeadline,

            USDTDeposited: userData ? parseFloat(ethers.utils.formatUnits(userData.usdt_deposited, 18)) : 0.00,
            tokensAmount: userData ? parseFloat(ethers.utils.formatUnits(userData.tokens_amount, tokenDecimals)) : 0.00,
            hasClaimed: userData?.hasClaimed || false,
        }

        return ICOEngine;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function scBuyWithUSDT(chainId, provider, account, amount) {
    const ICOContract = getContractObj('PresaleContract', chainId, provider);
    const USDTContract = getContractObj('USDT', chainId, provider);
    try {
        if (parseFloat(amount) < 50) {
            toast.error("The amount of USDT must be higher than 50 USDT!");
            return false;
        }
        const etherAmount: BigNumber = ethers.utils.parseUnits(amount.toString(), 18);
        const allowancedAmount: BigNumber = await USDTContract.allowance(account, ICOContract.address);
        if (allowancedAmount.lt(etherAmount)) {
            const tx = await USDTContract.approve(ICOContract.address, etherAmount);
            await tx.wait(1);
        }

        const tx = await ICOContract.buy_with_usdt(etherAmount);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scBuyWithBUSD(chainId, provider, account, amount) {
    const ICOContract = getContractObj('PresaleContract', chainId, provider);
    const BUSDContract = getContractObj('BUSD', chainId, provider);
    try {
        if (parseFloat(amount) < 50) {
            toast.error("The amount of BUSD must be higher than 50 BUSD!");
            return false;
        }
        const etherAmount: BigNumber = ethers.utils.parseUnits(amount.toString(), 18);
        const allowancedAmount: BigNumber = await BUSDContract.allowance(account, ICOContract.address);
        if (allowancedAmount.lt(etherAmount)) {
            const tx = await BUSDContract.approve(ICOContract.address, etherAmount);
            await tx.wait(1);
        }

        const tx = await ICOContract.buy_with_busd(etherAmount);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scBuyWithBNB(chainId, provider, amount) {
    const ICOContract = getContractObj('PresaleContract', chainId, provider);
    try {
        if (parseFloat(amount) < 0.2) {
            toast.error("The amount of BNB must be higher than 0.2 BNB!");
            return false;
        }
        const etherAmount: BigNumber = ethers.utils.parseEther(amount.toString());

        const tx = await ICOContract.buy_with_bnb({
            value: etherAmount
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scClaimTokens(chainId, provider) {
    const ICOContract = getContractObj('PresaleContract', chainId, provider);
    try {
        const tx = await ICOContract.claim_tokens();
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}