import { useEffect, useState } from 'react';
import './newEra.scss'
import { HashLink } from 'react-router-hash-link'
type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function NewEra({setIsLoading}:PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 5){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
   
    return (
        <div className=" newEra" >
            <div className="aboutContent">
                <div className="title" data-aos="fade-up">
                    <h1>The New Era of Trading</h1>
                </div>
                <div className="wrapper">
                    <div className="text_div left"  data-aos="fade-right">
                        <h2><span>Automated Trading</span></h2>
                        <p>Tradehub automates trading processes by executing trades based on predefined rules and algorithms.</p>
                        <p>By utilizing cutting-edge technology, Tradehub's automated system conducts trades with strategic precision, designed to capitalize on market movements swiftly and effectively.</p>
                        <HashLink to="#" smooth className = 'button'>Buy $TRHUB</HashLink>
                    </div>
                    <div className="img_div right" data-aos="fade-left">
                        <img src="/assets/images/1 2.png" alt="" onLoad = {onLoad}/>
                    </div>
                </div>

                <div className="wrapper col_reverse">
                    <div className="img_div left" data-aos="fade-right">
                        <img src="/assets/images/2.png" alt="" onLoad = {onLoad}/>
                    </div>
                    <div className="text_div right" data-aos="fade-left">
                        <h2><span>AI-Powered Signals</span></h2>
                        <p>Tradehub AI-powered algorithms can provide traders with accurate market analysis and predictions, helping them make more informed trading decisions.</p>
                        <HashLink to="#" smooth className = 'button'>Buy $TRHUB</HashLink>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="text_div left" data-aos="fade-right">
                        <h2><span>Trade Algorithm</span></h2>
                        <p>Tradehub AI can develop and optimize trading strategies based on historical data and market conditions.</p>
                        <HashLink to="#" smooth className = 'button'>Buy $TRHUB</HashLink>
                    </div>
                    <div className="img_div right" data-aos="fade-left">
                        <img src="/assets/images/3.png" alt="" onLoad = {onLoad}/>
                    </div>
                </div>

                <div className="wrapper col_reverse">
                    <div className="img_div left" data-aos="fade-right">
                        <img src="/assets/images/4.png" alt="" onLoad = {onLoad}/>
                    </div>
                    <div className="text_div right" data-aos="fade-left">
                        <h2><span>Fraud Detection</span></h2>
                        <p>Tradehub AI algorithms can help detect fraudulent trading activities by analyzing large volumes of trading data and identifying suspicious patterns or anomalies.</p>
                        <HashLink to="#" smooth className = 'button'>Buy $TRHUB</HashLink>
                    </div>
                </div>
            </div>
            <img src="/assets/images/Frame_02.png" alt="" className="effect" onLoad = {onLoad}/>
        </div>
    )
}
