import { useEffect, useState } from 'react';
import './video.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};


export default function VideoSection({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if(imgCount>=2){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [imgCount, setIsLoading]);

    const [isPlaying, setIsPlaying] = useState(false)
    const onPlay = ()=>{
        setIsPlaying(true)
    }

    return (
        <div className="video_section">
            <div className="content">
                <div className="title"  data-aos="fade-up">
                    <h1>Getting Deeper with TradeHub</h1>
                </div>
                <div className="wrapper"  data-aos="fade-up">
                    <div className="video_div">

                        <div className={`video_tag ${isPlaying? 'active_video':''}`}>
                            <iframe src='https://www.youtube.com/embed/cbva4EWUIDw'
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title='video'
                                className={`videoEmbed`}
                            />
                        </div>
                        
                   
                        {/* <video className={`video_tag ${isPlaying? 'active_video':''}`} ref = {videoRef} autoPlay={false} muted={true} playsInline  onLoadedData = {onLoad} onEnded={()=>setIsPlaying(false)}>
                            <source src="/assets/home page lcfc.mp4" type="video/mp4"/>
                        </video> */}
                        <div className="img_div">
                            <img src="/assets/photo.png" alt="" onLoad={onLoad} />
                        </div>
                    </div>
                    
                    {/* {!isPlaying && 
                    <div className="play_btn" onClick={onPlay}>
                        <div className="icon">
                        <svg viewBox="0 0 39 39" focusable="false" ><path fillRule="evenodd" clipRule="evenodd" d="M32.029 4.38951L6.6769 19.0161C6.20129 19.2905 6.20129 19.9769 6.6769 20.2513L32.029 34.8779C32.5044 35.1522 33.0984 34.8091 33.0984 34.2603L33.0984 5.00712C33.0984 4.45833 32.5044 4.11526 32.029 4.38951ZM31.6711 33.0236L8.45909 19.6335L31.6711 6.24122L31.6711 33.0236Z" fill="currentColor"></path><path d="M23.5674 19.0165C23.2752 19.1855 23.1479 19.5301 23.2417 19.8398L23.3072 19.9907L31.7685 34.6157C31.9657 34.9566 32.4019 35.0731 32.7428 34.8759C33.0349 34.7068 33.1622 34.3622 33.0684 34.0526L33.0029 33.9016L24.5416 19.2766C24.3444 18.9358 23.9082 18.8193 23.5674 19.0165Z" fill="currentColor"></path><path d="M8.08948 19.6338C8.08948 19.9784 8.30796 20.2658 8.59839 20.3323L8.72684 20.3468L23.8253 20.3468C24.1774 20.3468 24.4627 20.0276 24.4627 19.6338C24.4627 19.2892 24.2442 19.0017 23.9538 18.9353L23.8253 18.9208L8.72684 18.9208C8.37484 18.9208 8.08948 19.24 8.08948 19.6338Z" fill="currentColor"></path><path d="M23.3112 19.2698C23.1103 19.6085 23.2219 20.0459 23.5606 20.2469C23.8509 20.4191 24.2137 20.3617 24.438 20.1285L24.5376 19.9975L32.9989 5.73695C33.1998 5.39828 33.0882 4.96084 32.7495 4.7599C32.4592 4.58766 32.0964 4.64508 31.8721 4.87828L31.7725 5.00927L23.3112 19.2698Z" fill="currentColor"></path></svg>
                        </div>
                    </div>} */}
                    
                </div>
            </div>
            <img src="/assets/images/Frame_04.png" alt="" className="effect" onLoad = {onLoad}/>
        </div>
    )
}
