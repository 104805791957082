import './style.scss'

type PropsType = {
  onClick?: () => void;
  label ?:string
  color ?:string
  type?:string
  icon ?:any
};

export default function Button2({onClick, label, color, icon, type}:PropsType) {
  return (
    <button className={`my_btn2 button ${color} ${type}`} onClick={onClick}>
      <div className="button_content">
        <div className="line_1">
          <svg viewBox="0 0 91 80" focusable="false" style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M1.00798 0.978027L1.00803 60.3514C0.84803 65.8101 3.01708 79.978 13 79.978" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
        <div className="line_2">
          <svg viewBox="0 0 91 80" focusable="false" style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M5 0.978027V42.978C5 47.978 5.2 56.978 0 56.978" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
        <div className="line_3">
          <svg viewBox="0 0 91 80" focusable="false"  style={{width: 'auto', height: '100%', fill: 'none'}}><path d="M86.686 0.978027V34.478M86.686 34.478C89.0193 34.1447 90 32.278 90 27.478C90 22.678 90 8.47803 90 1.97803M86.686 34.478V38.478V42.478M1.13623 79.978C1.13623 76.778 6.13623 75.3114 8.63623 74.978H59.1362M59.1362 74.978H77.6362C82.0769 74.978 86.686 72.478 86.686 67.978V42.478M59.1362 74.978C59.4696 73.8114 60.1 69.978 64.5 69.978C68.9 69.978 73.9696 69.978 77.6362 69.978C78.8029 69.978 82.1362 69.978 82.1362 66.478C82.1362 63.678 82.1362 53.978 82.1362 47.978C82.1694 46.6447 84.7258 42.478 86.686 42.478" stroke="currentColor" strokeOpacity="0.2"></path></svg>
        </div>
      </div>
      <div className="label">
        {icon} {label}
      </div>
    </button>
  )
}
