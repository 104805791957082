import { useEffect, useState } from 'react';
import './roadmap.scss'

const firstData = [
    {
        level : 'Q1 2024',
        dec : ['Details coming soon'],

    },
    {
        level : 'Q2 2024',
        dec : ['Details coming soon'],

    },
    {
        level : 'Q3 2024',
        dec : ['Details coming soon'],

    },
    {
        level : 'Q4 2024',
        dec : ['Details coming soon'],

    },
    {
        level : 'Q1 2025',
        dec : ['Details coming soon'],

    },
]
type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function RoadMap({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 1){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    return (
        <div className="roadmap" id="roadmap">
            <div className="title" data-aos="fade-up">
                <h1>ROADMAP</h1>
                <p>Detailed action plan of how we are going to reshape the future of entertainment.</p>
            </div>

            <div className="roadmapContent">
                {firstData.map((d,i) =>(
                    <div className="col" key = {i}>
                    {i % 2 === 0 ? 
                    <>
                        <div className="node top" data-aos="fade-up">
                            <div className="content" data-aos="fade-right">
                                <div className="level">{d.level}</div>
                                {d.dec.length !== 0 &&
                                <div className="desc">
                                    {d.dec.map((e, k)=>(
                                        <p key = {k}>{e}</p>
                                    ))}
                                </div>}
                            </div>
                            <div className="circle" data-aos="fade-right"></div>
                            <div className="line" data-aos="fade-right"></div>
                            
                        </div>
                        <div className="node none"></div>
                    </>
                    :
                    <>
                        <div className="node none"></div>
                        <div className="node bottom" data-aos="fade-up">
                            <div className="line" data-aos="fade-right"></div>
                            <div className="circle" data-aos="fade-right"></div>
                            <div className="content" data-aos="fade-right">
                            <div className="level">{d.level}</div>
                            {d.dec.length !== 0 &&
                                <div className="desc">
                                    {d.dec.map((e, k)=>(
                                        <p key = {k}>{e}</p>
                                    ))}
                                </div>}
                            </div>
                        </div>
                    </>
                    }

                </div>
                ))}
                <img src="/assets/images/roadmal_node.png" alt="" className="bg" onLoad = {onLoad}/>
            </div>
        </div>
    )
}
