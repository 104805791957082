import { useEffect, useState } from 'react';
import './exchange_listing.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function ExchangeListing({setIsLoading}:PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 5){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="exchange_listing" >
            <div className="content">
                <div className="title" data-aos="fade-up">
                    <h1>$TRHUB Future Exchange Listing Plan</h1>
                </div>
                <div className="wrapper">
                    <ul>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Official_logo_of_OKEx 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Coinbase-logo-square-1 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Group 899.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/MEXC-New 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                {/* <div className="title" data-aos="fade-up">
                    <h1>$TRHUB Confirmed Exchange Listing</h1>
                </div>
                <div className="wrapper">
                    <ul>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/MEXC-New 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Group 899.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Official_logo_of_OKEx 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        <li data-aos="fade-up">
                            <div className="img_div">
                                <div className="img_content">
                                    <img src="/assets/images/Coinbase-logo-square-1 1.png" alt="" onLoad = {onLoad}/>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                </div> */}
            </div>
            <img src="/assets/images/Frame_05.png" alt="" className="effect1" onLoad = {onLoad}/>
        </div>
    )
}
